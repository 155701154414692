






























































































import {Component, Prop, Vue} from 'vue-property-decorator';
import ContactListItem from '@/components/ContactListItem.vue';
import {Action, Getter} from 'vuex-class';
import {BusinessContact, BusinessDepartment} from '@/domain/model/types';
import DialogDeleteDepartment from '@/components/directory/DialogDeleteDepartment.vue';
import DialogConfirm from '@/components/DialogConfirm.vue';
import {directoryStore} from '@/store/modules/directory/directoryStore';
import {tourStore} from "@/store/modules/tour";
import {profileStore} from "@/store/modules/profile";

@Component({
  name: 'contacts-directory',
  components: {DialogDeleteDepartment, ContactListItem, DialogConfirm}
})
export default class ContactsDirectory extends Vue {
  @Prop({default: false}) root?: boolean;
  @Prop({default: false}) customerView!: boolean;
  @Prop() contacts?: any[];

  dragOverId: string = ''
  showDeleteDepartment: boolean = false
  selectedDepartment?: BusinessDepartment
  dialogConfirm: boolean = false
  titleConfirm: string = ''
  textConfirm: string = ''
  textColorConfirm: string = ''
  confirm: string = ''
  onConfirm?: any;

  @Getter directoryInEditMode;

  @Action editDepartment;
  @Action deleteDepartment
  @Action deleteContact

  panelState(contactId: string) {
    return directoryStore.departmentsState[contactId] || 0
  }

  togglePanelState(contactId: string) {
    directoryStore.togglePanelState(contactId)
  }

  dragStartListener = (draggable: any) => {
    return () => {
      console.log('dragging start');
      draggable.classList.add('dragging');
    };
  };

  dragEndListener = (draggable: any) => {
    return () => {
      console.log('dragging end');
      draggable.classList.remove('dragging');
    };
  };

  // @Watch('directoryInEditMode') onEditModeChanged(after: boolean, before: boolean) {
  //     const draggables = document.querySelectorAll('.draggable');
  //     const containers = document.querySelectorAll('.drag-container');
  //     if (after) {
  //         draggables.forEach((draggable) => {
  //             draggable.addEventListener('dragstart', () => {
  //                 draggable.classList.add('dragging');
  //                 console.log('dragging start');
  //             });
  //             draggable.addEventListener('dragend', () => {
  //                 console.log('dragging end');
  //                 draggable.classList.remove('dragging');
  //             });
  //         });
  //         containers.forEach((container) => {
  //             container.addEventListener('dragover', () => {
  //                 console.log('drag over, id=' + container.id);
  //             });
  //         });
  //     } else {
  //         draggables.forEach((draggable) => {
  //             draggable.removeEventListener('dragstart', () => {
  //                 draggable.classList.add('dragging');
  //                 console.log('dragging start');
  //             });
  //             draggable.removeEventListener('dragend', () => {
  //                 console.log('dragging end');
  //                 draggable.classList.remove('dragging');
  //             });
  //         });
  //         containers.forEach((container) => {
  //             container.removeEventListener('dragover', () => {
  //             });
  //         });
  //     }
  // }

  onDragStart(id, event) {
    // console.log('onDragStart');
    console.log('onDragStart => id=' + id + '\nevent=' + JSON.stringify(event));
    this.dragOverId = '';
  }

  onDragOver(id, event) {
    // console.log('onDragOver');
    console.log('onDragOver => id=' + id + '\nevent=' + JSON.stringify(event));
    this.dragOverId = '';
  }

  onDrop(id, event) {
    // console.log('onDrop');
    console.log('onDrop => id=' + id + '\nevent=' + JSON.stringify(event));
    this.dragOverId = '';
  }

  directoryContacts(contacts: any[]) {
    return this.customerView
        ? contacts.filter((item) => !!item.rules.VISIBILITY.visible)
        : contacts
  }

  borderLeftStyle(color) {
    return {
      borderLeft: `4px solid ${color || '#3773F5'}`
    };
  }

  invisible(contact: BusinessContact) {
    return contact?.rules && !contact.rules.VISIBILITY?.visible;
  }

  active(contacts): number {
    if (!contacts) {
      return 0;
    }
    return contacts.filter(item => {
      if (item.associate) {
        return !!item.associate.status?.online;
      }
      if (item.contacts) {
        return !!item.contacts.find(item => !!item.associate?.status?.online);
      }
      if (item.type === 3) {
        return this.active(item.contacts);
      }
      return false;
    }).length;
  }

  confirmDeleteDepartment(department: BusinessDepartment) {
    this.selectedDepartment = department
    this.showDeleteDepartment = true
  }

  onDeleteDepartment({deleteAll, department}) {
    if (!department) {
      return;
    }
    this.deleteDepartment({deleteAll, department})
  }

  onSelectContact(item: BusinessContact) {
    this.$router.push({name: 'contact-info', params: {contactId: item.id! }});
  }

  onDeleteConfirm(contact) {
    this.titleConfirm = `Do you want to delete contact ‘${contact.name}’ ?`
    this.textConfirm = `<div class="mx-5">Warning: The contact will be removed from directory.<br>` +
        `This action can’t be undone.</div>`
    this.confirm = 'Yes, delete'
    this.textColorConfirm = 'text-secondary'
    this.onConfirm = async () => {
      this.dialogConfirm = false
      this.deleteContact(contact)
    };
    this.dialogConfirm = true
  }

  created() {
    // if (tourStore.tourNumber === 6 && profileStore.isAdmin && this.root) {
    //   tourStore.setTourSelectedObj(null)
    // }
  }

  mounted() {
    // if (tourStore.tourNumber === 6 && profileStore.isAdmin) {
    //   if (tourStore.tourSelectedObj === null) {
    //     if (typeof Object.keys(this.$refs)[0] !== 'undefined') {
    //       const htmlElement: HTMLElement = this.$refs[Object.keys(this.$refs)[0]][0]['$el'] as HTMLElement
    //       tourStore.setTourSelectedObj(htmlElement.getBoundingClientRect())
    //     }
    //   }
    // }
  }
}
